import AOS from 'aos';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

ScrollTrigger.normalizeScroll(true);

export function initAnimations() {
  AOS.init({ startEvent: 'load' });

  const globalCounter = document.querySelectorAll(
    '.counter-selector-animation',
  );

  //check if globalCounter exists
  if (globalCounter.length > 0) {
    globalCounter.forEach((counter) => {
      gsap.from(counter, {
        textContent: 0,
        duration: 1,
        snap: { textContent: 1 },
        scrollTrigger: {
          toggleActions: 'restart none reverse none',
          trigger: counter,
          start: 'top 80%',
          end: 'bottom 100%',
        },
        onComplete: () => {
          counter.innerHTML = counter.innerHTML + '+';
        },
      });
    });
  }

  document.querySelectorAll('.main-button').forEach((button) => {
    button.addEventListener('click', (e) => {
      const href = e.target.getAttribute('href');

      // Check if the link is an internal anchor
      if (href.startsWith('#')) {
        e.preventDefault(); // Prevent default anchor behavior

        const targetSection = document.querySelector(href);
        if (targetSection) {
          gsap.to(window, {
            duration: 1, // Smooth scroll duration
            scrollTo: targetSection,
            ease: 'power2.inOut',
          });
        }
      }
    });
  });

  document.querySelectorAll('.nav-item').forEach((link) => {
    const targetId = link.getAttribute('href');
    if (targetId && targetId.startsWith('#')) {
      link.addEventListener('click', (e) => {
        e.preventDefault();

        console.log('link click');

        const targetId = e.target.getAttribute('href');
        const targetElement = document.querySelector(targetId);

        if (targetElement) {
          gsap.to(window, {
            duration: 1,
            scrollTo: targetElement,
            ease: 'power2.inOut',
          });
        }
      });
    }
  });

  const windAnimation = document.querySelector('.wind-animation');
  if (windAnimation) {
    var paths = windAnimation.querySelectorAll('path');
    paths.forEach((path, index) => {
      var l = path.getTotalLength();
      gsap.set(path, { strokeDasharray: l });
      gsap.set(path, { strokeDashoffset: -l });
      gsap.to(path, 0.5, { strokeDashoffset: 0, delay: index * 0.1 });
    });
  }

  const gifElement = document.getElementById('myGif');

  if (gifElement) {
    const originalSrc = gifElement.src;
    ScrollTrigger.create({
      trigger: '.gif-container',
      start: 'top 80%',
      onEnter: () => {
        gifElement.src = originalSrc;
      },
    });
  }

  const pfeilRaus = document.querySelectorAll('.luft-raus-pfeile svg path');
  const pfeilRein = document.querySelectorAll('.luft-rein-pfeile svg path');

  if (pfeilRaus.length > 0) {
    gsap.from(pfeilRaus, {
      x: 200,
      opacity: 0,
      duration: 0.5,
      stagger: 0.05,
      scrollTrigger: {
        toggleActions: 'restart none reverse none',
        trigger: pfeilRaus,
        start: 'top 50%',
        end: 'bottom 100%',
      },
    });

    gsap.from(pfeilRein, {
      x: -200,
      opacity: 0,
      duration: 0.5,
      stagger: 0.05,
      scrollTrigger: {
        toggleActions: 'restart none reverse none',
        trigger: pfeilRaus,
        start: 'top 50%',
        end: 'bottom 100%',
      },
    });
  }

  window.addEventListener('resize', () => {
    ScrollTrigger.refresh(); // refresh ScrollTrigger after a resize event
  });
}
