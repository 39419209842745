import Splide from '@splidejs/splide';

export function initializeSplide() {
  document.addEventListener('DOMContentLoaded', function () {
    var splideGalleryElelement = document.querySelector('.splide.gallery');
    var splideEventsElelement = document.querySelector('.splide.events');
    var splideBilderElement = document.querySelector('.splide.bilder');

    if (splideGalleryElelement) {
      var splideGallery = new Splide(splideGalleryElelement, {
        type: 'loop',
        // autoplay: true,
        drag: true,
        perPage: 3,
        perMove: 1,
        arrows: false,
        autoWidth: true,
        pagination: false,
        breakpoints: {
          1024: {
            perPage: 2,
            gap: '0rem',
          },
          768: {
            perPage: 1,
            gap: '0rem',
          },
        },
      });
      splideGallery.mount();

      var forwardButton = document.querySelector('.gallery .splide-left');
      var backwardButton = document.querySelector('.gallery .splide-right');

      forwardButton.addEventListener('click', function () {
        splideGallery.go('<');
      });

      backwardButton.addEventListener('click', function () {
        splideGallery.go('>');
      });
    }
    if (splideEventsElelement) {
      var splideEvents = new Splide(splideEventsElelement, {
        type: 'loop',
        // autoplay: true,
        drag: true,
        perPage: 4,
        perMove: 1,
        arrows: false,
        pagination: false,
        autoHeight: true,
        gap: '3rem',
        breakpoints: {
          1356: {
            perPage: 3,
            gap: '3rem',
          },
          900: {
            perPage: 2,
            gap: '3rem',
          },
          530: {
            perPage: 1,
            gap: '1rem',
          },
        },
      });
      splideEvents.mount();

      var forwardButton = document.querySelector(
        '.events-section .splide-left',
      );
      var backwardButton = document.querySelector(
        '.events-section .splide-right',
      );

      forwardButton.addEventListener('click', function () {
        splideEvents.go('<');
      });

      backwardButton.addEventListener('click', function () {
        splideEvents.go('>');
      });
    }

    if (splideBilderElement) {
      var splideBilder = new Splide('.splide.bilder', {
        type: 'loop',
        drag: false,
        perPage: 1,
        perMove: 1,
        arrows: false,
        gap: '2rem',
        pagination: false,
      });

      splideBilder.mount();
    }
  });
}
