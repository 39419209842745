import './styles/main.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { initializeSplide } from './scripts/splide.js';
import { initLazyLoad } from './scripts/lazyload.js';
import { initAnimations } from './scripts/animations.js';
import { initForms } from './scripts/forms.js';
import { playVideo } from './scripts/playvideo.js';
import { handleResize } from './scripts/resize.js';

initializeSplide();

document.addEventListener('DOMContentLoaded', () => {
  initLazyLoad(); // Initialize Lazy Loading
  initForms(); // Initialize Form Validations
  initAnimations();
  playVideo();
});

window.addEventListener('load', () => {
  AOS.refresh();
  setTimeout(() => {
    AOS.refresh();
  }, 1000);
});

window.addEventListener('resize', handleResize);
